import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const Footer = () => {
    return (
        <Box
            component="footer"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '36px',
                backgroundColor: '#f7f7f7',
                color: 'primary.contrastText',
                width: '100%',
                position: 'fixed',
                bottom: 0,
                left: 0
            }}
        >
            <Container>
                <Typography variant="body1" align="center">
                    FLEET by LKW-Walter © 2024 IT4S Silvertip IT-Solutions GmbH. All rights reserved.
                </Typography>
            </Container>
        </Box>
    );
};

export default Footer;