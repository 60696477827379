import React from "react";
import {Box, Container, Fade, Stack, Typography} from "@mui/material";
import ResponsiveImage from "../Components/ResponsiveImage";
import RedirectComponent from "./Redirect.tsx";
import Footer from "./Footer.tsx";

const Home = () => {

    function isDateBeforeToday(date: Date): boolean {
        return new Date(date.toDateString()) < new Date(new Date().toDateString());
    }

    return <>
        <Fade in={true} timeout={1000}>
            <Box sx={{display: 'block'}}>
                <ResponsiveImage src="/images/lkwwalter_login_background.png" sx={{height: "400px"}}/>
            </Box>
        </Fade>
        <Container>
            <Box sx={{minHeight: '100vh', position: 'relative', paddingBottom: '36px'}}>
                {!isDateBeforeToday(new Date(2024, 11, 18, 17, 0, 0)) ?
                    <Stack sx={{mt: 4}}>
                        <Typography variant='h5'>
                            FLEET by LKW WALTER zieht um!
                        </Typography>
                        <Typography>
                            Unsere Webseite wird derzeit auf eine neue Plattform umgezogen und ist deshalb vorübergehend
                            offline.
                            <br/>
                            <Typography fontWeight={"bold"}>
                                Geplante Wiederverfügbarkeit: Sonntag 17. November 2024, 17:00 Uhr
                            </Typography>
                            Wir danken Ihnen für Ihr Verständnis und freuen uns darauf, Sie bald auf unserer neuen
                            Webseite
                            begrüßen zu dürfen!
                        </Typography>
                        <Typography variant='h5' sx={{mt: 4}}>
                            FLEET by LKW WALTER is moving!
                        </Typography>
                        <Typography>
                            Our website is currently offline as we transition to a new platform.
                            <br/>
                            <Typography fontWeight={"bold"}>
                                Expected Availability: Sunday 17. November 2024, 5:00 PM
                            </Typography>
                            Thank you for your understanding, and we look forward to welcoming you to our new website
                            soon!
                        </Typography>
                    </Stack>
                    :
                    <Stack id='headline' sx={{mt: 4}}>
                        <Typography variant='h4'>
                            FLEET by LKW WALTER ist umgezogen!
                        </Typography>

                        <Stack id='greating' direction='row'>
                            <Stack>
                                <Typography variant="body1" sx={{fontSize: "1.2rem"}} textAlign="justify">
                                    Fürs Erste werden Sie weiterhin automatisch auf die neue URL weitergeleitet. Künftig
                                    rufen Sie
                                    bitte direkt die neue Website unter folgendem Link auf:<br/> <a
                                    href={"https://fleet.lkw-walter.com"}>https://fleet.lkw-walter.com</a>.
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack id='headline' sx={{mt: 8}}>
                            <Typography variant='h4'>
                                FFLEET by LKW WALTER has moved!
                            </Typography>
                        </Stack>
                        <Stack id='greating' direction='row'>
                            <Stack>
                                <Typography variant='body1' sx={{fontSize: '1.2rem'}} textAlign='justify'>
                                    For now, you'll be automatically redirected to the new URL. In the future, please go
                                    directly to the new website at:<br/> <a
                                    href={"https://fleet.lkw-walter.com"}>https://fleet.lkw-walter.com</a>.
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack sx={{mt: 8}}><RedirectComponent/></Stack>
                    </Stack>
                }
                <Footer/>
            </Box>
        </Container>
    </>
}

export default Home