import React, {useEffect, useState} from "react";
import {Typography, Container, CircularProgress, Box} from "@mui/material";

const RedirectComponent = () => {
    const [timer, setTimer] = useState(10);

    useEffect(() => {
        if (timer > 0) {
            const countdown = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);

            return () => clearInterval(countdown);
        } else {
            window.location.href = 'https://fleet.lkw-walter.com';
        }
    }, [timer]);

    return (
        <Container style={{textAlign: "center", marginTop: "8"}}>
            <Box sx={{position: "relative", display: "inline-flex"}}>
                <CircularProgress style={{marginTop: 20}} size={150} variant="determinate" value={100 / timer} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        sx={{color: "text.secondary", padding: "5px", fontSize: "0.85em", mt:"17px"}}
                    > Weiterleitung<br /> Redirected<br /> in {timer} s</Typography>
                </Box>
            </Box>
        </Container>
    );
};

export default RedirectComponent;